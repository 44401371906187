.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 300ms ease-in-out;
    opacity: 0;
    pointer-events: none;
  }
  
  .modal-backdrop.show {
    opacity: 1 !important;
    pointer-events: auto;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    transform: scale(0.9);
    transition: transform 300ms ease-in-out;
  }
  
  .modal-content.show {
    transform: scale(1);
  }